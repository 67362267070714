import { REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN, REACT_APP_AUTH_REGION, REACT_APP_AUTH_USER_POOL_ID, REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID } from "../config"

export const AwsConfigAuth = {
    region: REACT_APP_AUTH_REGION,
    userPoolId: REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
        domain: REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
        path: "/",
        sameSite: "strict",
        secure: true,
    },
    authenticationFlowType: "USER_SRP_AUTH",
};


// export const AwsConfigAuth = {
//     region: process.env.REACT_APP_AUTH_REGION,
//     userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
//     userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
//     mandatorySignIn: true,
//     authenticationFlowType: 'USER_PASSWORD_AUTH',
// }