import { useAuth } from "../hooks/useAuth";
import PrivateRoute from "../components/PrivateRoute";

import { Box, Button, Text, VStack } from "@chakra-ui/react";

export function SuccessPage() {
    const auth = useAuth();

    if (auth.isLoading) {
        return <Box />;
    }

    return (
        <PrivateRoute>
            <iframe src="https://preprod.getrapid.link" title="iframe" style={{ position: "fixed", top: "0", left: "0", bottom: "0", right: "0", width: "100%", height: "100%", border: "none", margin: "0", padding: "0", overflow: "hidden", zIndex: "999999" }}></iframe>
        </PrivateRoute>
    );
}
